

















import { Component, Emit, Model, Prop, Vue, Watch } from "vue-property-decorator";
import Rules from "@/app/modules/_global/classes/Rules";

@Component
export default class RadioGroup extends Vue {
  @Prop(Array) readonly items!: object;
  @Prop(String) readonly label!: string;

  @Model("input", { type: Array }) readonly data!: Array<string>;

  @Emit()
  input() {
    return this.localData;
  }

  @Watch("checkbox")
  onLocalDataChanged() {
    this.localData = this.checkbox;
    this.input();
  }

  localData: {};
  checkbox = [];

  rules = {
    required: Rules.required
  };

  constructor() {
    super();

    this.localData = this.data;
  }
}
